/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Spinner,
  Form,
  FormGroup,
  Input,
  Label,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalBody,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  UncontrolledCollapse
} from "reactstrap";
// tooltips
import { OverlayTrigger, Popover } from "react-bootstrap"

import Select from 'react-select';



// plugins
import axios from "axios";
import swal from '@sweetalert/with-react';
import moment from 'moment';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import GlobalFunctions from "../controllers/GlobalFunctions";
import API_URL from '../variables/API_URL';
import CorrectiveForm from "views/CorrectiveAction";

import { thead, tbody } from "variables/general";

class IncidentRegister extends React.Component {

  state = {
    visible: true,
    clients: [],
    showModal: false,
    showCorrectiveModal: false,
    showIndividualModal: false,
    showViewModal: false,
    globalData: [],
    staffs: [
      {
        "id": 2,
        "firstname": "kethib",
        "lastname": "chitimbe"
      },
      {
        "id": 3,
        "firstname": "abraham",
        "lastname": "chitimbe"
      }
    ],
    impactAssessmentDescription: [],
    witnesses: [],
    selectedParticipantId: "",
    selectedClientId: "",
    selectedIncidentWitnesses: [],
    selectedIncidentId: 0,
    contactedRadios: "false",
    incidents: [],


    actionsToBeCompleted: [],
    control: [],
    actions: [],

    incidentsIdArray: [],

    selectedReportedById: 0,
    selectedReportedToId: 0,
    selectedClientValue: { value: 0, label: 'Seach client' },

    categories: [
      {
        name: "Participant Medical",
        description: ["Medication – Administration Error", "Medication – Incorrect packaging", "Medication- Participant Refusal/Missed Medication", "Medical event (e.g. choking, seizure", "Medication – Omitted", "Medication – Observed incorrect self-administration"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Any incident or event that does not require treatment, (this may also include a medication error, healthdirect were contacted and advised to continue with current dose routine."]
          },
          {
            name: "Minor",
            description: ["First Aid treatment", "Health direct contacted and they made recommendations e.g. monitoring", "Requires follow up with medical professional e.g. GP, nurse", "Seizure/Choking /injury (may include review by paramedics to assess whether a person requires hospital assessment)", "Medication (PRN) for medical e.g. diabetes (Low Blood Glucose Level),asthma inhaler"]
          },
          {
            name: "Moderate",
            description: ["Any hospitalisation due to a medical condition (Presentation or admission to an emergency or other ward within a hospital facility, including short-stay admission.)", "Lost time injury (Supported employees only)", "Any Medical event resulting in serious participant injury i.e. epilepsy seizure, heart attack, stroke resulting in: Fractures, Burns, Deep cuts, Extensive bruising, Head or brain injuries resulting in concussion or loss of consciousness"]
          },
          {
            name: "Major",
            description: ["Any Medical event that results in life threatening impacts requiring hospital admission.", "Medication administration error that results in hospitalisation or serious complications to participant.", "Life threatening personal harm through medication refusal or overuse resulting in hospitalisation."]
          },
          {
            name: "Critical",
            description: ["Death of a participant", "Life threatening complications", "Life threatening complications", "Participant on Life support", "Participant in an Induced Coma"]
          }
        ]
      },
      {
        name: "Participant Safeguarding",
        description: ["Minor injury of a participant", "Serious injury of a participant", "Abuse of a participant", "Neglect of a participant", "Sexual misconduct", "Unlawful physical contact", "Unlawful sexual contact", "Death of a participant", "Unauthorised use of restrictive practice"],
        impactRating: [
          {
            name: "insignificant",
            description: ["An incident that does not require medical treatment", "A near miss.", "An incident that does not cause physical/psychological harm."]
          },
          {
            name: "Minor",
            description: ["Minor Injury to a participant requiring first aid treatment"]
          },
          {
            name: "Moderate",
            description: ["Allegation of abuse including physical, sexual and/or psychological by a community member when we are NOT providing direct supports", "An unauthorised use of restrictive practices: Seclusion, Chemical, Environment, Physical, Mechanical", "An identified pattern of incidents relating to or involving one participant.", "Minor concerns for welfare/leaving of rostered supports/refusal of supports resulting in/or potential to cause minor harm to self (No protocol in place for known behaviour), staff and/or members of the community.", "Allegation of abuse including physical and/or psychological by a participant.", "Allegation of abuse including physical and/or psychological by a community member when we are providing direct supports", "Alleged Fraudulent abuse of a participant by a staff member."]
          },
          {
            name: "Major",
            description: ["Serious injury to a participant: Fractures, Burns, Deep cuts, Extensive bruising, Head or brain injuries resulting in concussion or loss of consciousness, Any other injury requiring hospitalisation", "Allegation of neglect of a participant involving a staff member: inadequate care, supervisory neglect,failure to protect from abuse, inadequate medical care", "Allegation of abuse of a participant including physical, psychological, financial or systemic abuse", "Staff alleged unlawful physical contact with the participant", "Allegation of unlawful sexual acts or misconduct against a participant: Acts of indecency, Grooming behaviour, Crossing professional boundaries in a way that has sexual implications or connotations, Incidents involving a person under the age of 16", "Major concerns for welfare/leaving of rostered supports/refusal of supports resulting in/or potential to cause moderate harm to self (No protocol in place for known behaviour), staff and/or members of the community."]
          },
          {
            name: "Critical",
            description: ["Death or multiple life-threatening injuries of a participant", "Any allegation of sexual assault of a participant", "Any incident/or allegation of severe physical, emotional or psychological injury or suffering which is likely to cause ongoing trauma", "Serious concerns for welfare/leaving of rostered supports/refusal of supports resulting in serious harm to self (No protocol in place for known behaviour), staff and/or members of the community."]
          }
        ]
      },
      {
        name: "Participant Behaviour",
        description: ["Behaviours of concern of a participant to self", "Behaviours of concern of a participant towards other participants", "Behaviours of concern of a participant towards community members", "Behaviours of concern of a participant towards staff", "Offensive or abusive behaviour towards others", "Possession of weapons, explosive or dangerous goods or objects", "Illegal or unlawful behaviour e.g. breach of a court/community/treatment order", "Suicide attempt", "Absconding/Missing persons", "Sexualised Behaviour"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Behaviours of concern resulting in no injury or damage (e.g. nonintentional disruptive actions, not following instructions, doing the  same thing repeatedly etc.)"]
          },
          {
            name: "Minor",
            description: ["Behaviours of concern causing minor disruption (e.g. minor delay in doing activity).", "Offensive or abusive behaviour towards others but not resulting in emotional or physical harm", "Minor breach of the law by a participant resulting in no action required by authorities (e.g. theft of a chocolate bar)", "Sexualised behaviour – no harm"]
          },
          {
            name: "Moderate",
            description: ["Behaviours of concern causing/could cause injury to staff or members of the public", "Behaviours of concern resulting in minor property damage (e.g.breaking things)", "Sexualised behaviours towards staff", "Sexualised behaviours towards members of the community", "Breach of an order (court, treatment, community, etc.)", "Offensive or abusive behaviour towards others resulting in emotional/physical harm towards staff.", "Offensive or abusive behaviour towards others resulting in emotional/physical harm towards members of the community.", "Criminal offence committed by a participant, requiring report to the Police or attendance at court.", "Participant observed/not observed absconding resulting in minor harm to self or other participants.", "Unknown/unannounced presence resulting in harm to self or other participants.", "DSA process/protocol that puts participants at risk", "Sexualised behaviours towards other participants", "Behaviours of concern causing/could cause injury to self or other participant", "Offensive or abusive behaviour towards other participants, resulting in emotional/physical harm."]
          },
          {
            name: "Major",
            description: ["Behaviours of concern resulting in major harm to self or others.", "Behaviours of concern resulting in major property damage", "Possession and/or use of weapon, explosive or dangerous goods and objects", "Indictable offence committed by participant", "Sexually inappropriate behaviours towards other participants, members of the community and/or staff", "Suicide attempt resulting in moderateserious injury", "Participant observed/not observed absconding resulting in serious harm to self or other participants.", "Unknown/unannounced presence resulting in serious harm to self or other participants."]
          },
          {
            name: "Critical",
            description: ["Behaviour of concern resulting in serious injury/harm to self or others", "Suicide attempt resulting in serious injury/ life threatening.", "Significant Property damage", "Multiple offences committed by participant, police intervention for serious criminal offence, unannounced police intervention.", "Unknown/unannounced presence resulting in life threatening harm to self or other participants."]
          }
        ]
      },
      {
        name: "Work health",
        description: ["N/A"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Minor injury not requiring First Aid treatment", "A near miss"]
          },
          {
            name: "Minor",
            description: ["Minor injury requiring First Aid treatment", "Short-term medical intervention with full recovery within 5 days", "No lost time injury"]
          },
          {
            name: "Moderate",
            description: ["Harm requiring medical treatment: short term rehabilitation required", "Full recovery within 3 months.", "Lost time injury"]
          },
          {
            name: "Major",
            description: ["Life threatening personal harm or multiple serious cases causing hospitalisation", "Significant impairment requiring mediumlong-term rehabilitation", "Partial/full recovery within 4 – 12 months", "Notifiable incident to relevant authority", "Near miss with the potential for causing major impact to safety"]
          },
          {
            name: "Critical",
            description: ["Death or permanent disability", "Serious security threat and/or serious assault towards staff", "Notifiable incident to relevant external authority", "Near miss with the potential for causing critical impact to safety"]
          }
        ]
      },
      {
        name: "Premises assets",
        description: ["AMS Property / vehicle damage"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Minor property damage, no repairs required"]
          },
          {
            name: "Minor",
            description: ["Property damage requiring repairs with costs within budget"]
          },
          {
            name: "Moderate",
            description: ["Property damage requiring repairs with costs exceeding budget and some operational interruption", "Intentional property damage by participants, staff or member of public"]
          },
          {
            name: "Major",
            description: ["Significant property damage requiring repairs with costs exceeding budget with operational interruption and/or causing cancellation of service delivery", "Dangerous Incidents defined by SafeWork NSW. Including:", "an uncontrolled escape, spillage or leakage of a substance", "an uncontrolled escape, spillage or leakage of a substance", "an uncontrolled escape of gas or steam", "an uncontrolled escape of a pressurised substance", "electric shock causing serious injury/death"]
          },
          {
            name: "Critical",
            description: ["Extensive property damage resulting in inability to run multiple services"]
          }
        ]
      },
      {
        name: "Environment",
        description: ["Environmental damage"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Minor impact on environment", "No authority action required can be contained and addressed immediately"]
          },
          {
            name: "Minor",
            description: ["Environmental damage or breach causing clean-up costs and notification to authority. No fine imposed"]
          },
          {
            name: "Moderate",
            description: ["Significant environmental damage or breach causing large clean-up costs and operational issues", "Notification to, and investigation by authority. No fine imposed"]
          },
          {
            name: "Major",
            description: ["Major environmental damage or breach causing major clean-up costs and operational issues/minor service disruption", "Notification to, and investigation by authority. Fine imposed"]
          },
          {
            name: "Critical",
            description: ["Extensive environmental damage causing temporary or partial closure of services and resulting in civil/criminal action against DSA"]
          }
        ]
      },
      {
        name: "Legal-Compliance-Regulatory",
        description: ["Unauthorised disclosure of confidential information", "Litigation and/or breach of regulations, compliance or legal action"],
        impactRating: [
          {
            name: "insignificant",
            description: ["A minor issue, concern or advice sought on an issue or potential issue related to legal and compliance"]
          },
          {
            name: "Minor",
            description: ["Isolated regulatory action resolved by management", "Threat of legal action resolved by management", "Non-compliance events with no material impact"]
          },
          {
            name: "Moderate",
            description: ["Regulatory breach", "Threat of legal action requiring external advice and legal management", "unaddressed or continuing noncompliance activity", "Criminal offence committed at DSA premises including bomb threat, suspicious package, hold up, assault"]
          },
          {
            name: "Major",
            description: ["Litigation and/or serious breach of regulation", "Loss of significant contract and future tenders potentially affected", "criminal charges", "Systemic non-compliance activity reported to regulator", "Actual criminal offence such as bomb, armed hold up, serious assault"]
          },
          {
            name: "Critical",
            description: ["Major litigation and/or major criminal charges", "Prosecution/fines against key personnel", "Removal of authorisation to operate due to non-compliance"]
          }
        ]
      },
      {
        name: "Financial",
        description: ["Fraudulent behaviour of a staff member"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Actual or potential unplanned financial loss (over expenditure or under income or cash flow delays) within an individual cost centre or location", "Overall annual net result for a division likely to be underachieved by over $10,000 or 5% (whichever is the greater)"]
          },
          {
            name: "Minor",
            description: ["Actual or potential unplanned financial loss (over expenditure or under income or cash flow delays) within division. The loss in one location is not likely to be offset by gains in other locations within division", "Overall annual net result for a division is likely to be underachieved by more than $50,000 or 5% (whichever is the greater)"]
          },
          {
            name: "Moderate",
            description: ["Actual or potential unplanned financial loss (over expenditure or under income or cash flow delays) across organisation. The loss in one division is not likely to be offset by gains in other divisions", "Overall annual net result for organisation is likely to be underachieved by more than $200,000 but less than $1,000,000 or 5% (whichever is the greater)"]
          },
          {
            name: "Major",
            description: ["Actual or potential unplanned financial loss (over expenditure or under income or cash flow delays) across organisation. The loss in one division is not likely to be offset by gains in other divisions", "Overall net result for organisation is likely to be underachieved by more than $1,000,000 or 5% (whichever is the greater)"]
          },
          {
            name: "Critical",
            description: ["Actual or potential unplanned financial loss (over expenditure or under income or cash flow delays) across organisation. The loss in one divisionis not likely to be offset by gains in other divisions", "Overall net result for organisation is likely to be underachieved by more than $1,000,000 or 10% of the organisation's net assets (whichever is the greater)"]
          }
        ]
      },
      {
        name: "Business Interruption",
        description: ["Disruption to service delivery"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Business interruption resulting in minor operational impact (e.g. water/power/Internet outage)"]
          },
          {
            name: "Minor",
            description: ["Business interruption resulting in minor disruption to operations (1-2 days)", "Disruptions that require reallocation of resources", "Short term impact on quality,consistency and reliability of services"]
          },
          {
            name: "Moderate",
            description: ["Business interruption affecting strategic or business plan implementation", "Operational disruption of up to 5 days", "Continuing impact on quality, consistency and reliability of services in an isolated area"]
          },
          {
            name: "Major",
            description: ["Interruption of service provision or program termination", "Deviation from strategic plan and growth targets", "Major disruption to operations of 5 – 10 days", "Widespread impact on quality, consistency and reliability of services"]
          },
          {
            name: "Critical",
            description: ["Loss or potential loss of operating licences", "Discontinue service provision", "Major disruption to operations of more than 10 days (major IT outage)"]
          }
        ]
      },
      {
        name: "Reputational-Complaints",
        description: ["Social Media posts with the potential to cause reputational damage to DSA"],
        impactRating: [
          {
            name: "insignificant",
            description: ["Isolated participant complaint/issue resolved by day to day local operational management", "One off negative reporting in social media", "Unresolved complaint resulting in dissatisfaction/ frustration"]
          },
          {
            name: "Minor",
            description: ["Formal complaints/issues, possible local press/social media mention, frontline management involved and informal internal review required", "Temporary negative impact on reputation", "Some negative reporting in media", "Unresolved complaint leading to external investigation"]
          },
          {
            name: "Moderate",
            description: ["Formal complaints/issues with local press coverage, senior management involved in resolution, formal internal review required and reportable externally.", "Temporary breakdown in key external relationship", "Widespread negative reporting on social media pages", "Prosecution of a staff member"]
          },
          {
            name: "Major",
            description: ["Widespread negative publicity and media scrutiny, CEO/Board management, formal internal review required, and possible external review sought, investigation by external authority", "Ongoing widespread negative reporting in media", "High-level independent investigation with adverse findings", "Civil action or prosecution against the organisation"]
          },
          {
            name: "Critical",
            description: ["Severe negative publicity and media coverage.", "Loss of community participation and confidence.", "Public enquiry investigation by regulator/authorities resulting in temporary or permanent license(s) suspension", "Any Class action against the organisation"]
          }
        ]
      }

    ]
  }

  //RUNS WHEN COMPONENT IS MOUNTED
  componentDidMount = () => {
    // this.getIncidents();
    this.calculateWeekRange();
    this.getStaffs();
    this.getClients();
  }

  //HANDLE INPUT VALUES
  inputValue = (event) => {
    this.setState({ [event.name]: event.value })
  }

  //EDIT INCIDENT IN THE DATABASE
  handleEditIncident = (event) => {
    event.preventDefault();
    const data = this.state.incidents;
    const index = data.findIndex(x => x.incidentId == this.state.selectedIncidentId);
    const infor = {
      "staffId": this.state.staffId == undefined ? data[index].staffId : Number(this.state.staffId),
      "clientId": this.state.clientId == undefined ? data[index].clientId : Number(this.state.clientId),
      "contacted": this.state.selectedIncidentContacted == this.state.contacted ? data[index].contacted : this.state.contacted,
      "location": this.state.siteLocation == undefined ? data[index].location : this.state.siteLocation,
      "date": `${this.state.date == undefined ? moment(data[index].date).format('MM/DD/yyyy') : moment(this.state.date).format('MM/DD/yyyy')} ${this.state.time == undefined ? moment(data[index].date).format('HH:mm') : this.state.time}`,//=="undefined undefined"? data[index].date: `${this.state.date} ${this.state.time}`,
      "witnesses": this.state.selectedIncidentWitnesses == data[index].witnesses ? data[index].witnesses : this.state.selectedIncidentWitnesses,
      "category": this.state.category == undefined ? data[index].category : this.state.category,
      "descriptionBefore": this.state.descriptionBefore == undefined ? data[index].descriptionBefore : this.state.descriptionBefore,
      "descriptionDuring": this.state.descriptionDuring == undefined ? data[index].descriptionDuring : this.state.descriptionDuring,
      "descriptionAfter": this.state.descriptionAfter == undefined ? data[index].descriptionAfter : this.state.descriptionAfter,
      "initialActionTaken": this.state.initialActions == undefined ? data[index].initialActionTaken : this.state.initialActionTaken,
      "practiceLeader": this.state.practiceLeader == undefined ? data[index].practiseLeader : this.state.practiceLeader,
      "contacted": this.state.contactedRadios == "true" ? this.state.contacted : this.state.contactedRadios,
      "isReportable": this.state.isReportable == undefined ? data[index].isReportable : this.state.isReportable,
      "impactAssessiment": this.state.impactAssessment == undefined ? data[index].impactAssessiment : this.state.impactAssessment,
      "policeReferenceNumber": this.state.referenceNumber == undefined ? data[index].policeReferenceNumber : this.state.policeReferenceNumber
    }

    var url = `${API_URL.url}/incidents/edit?id=${this.state.selectedIncidentId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.post(url, infor).then(incident => {
      this.setState({ ShowModal: false })
      this.getIncidents();
      swal("Changes Saved successfully!", "", "success");
    }).catch(error => {
      let errorObject = JSON.parse(JSON.stringify(error));
      swal(`${errorObject.message}!`, "Failed to update, click okay and try again!", "warning");
    })

  }

  //SUBMIT INCIDENT FORM TO DATABASE
  handleSubmitCorrectiveAction = (event) => {

    event.preventDefault();
    this.setState({ savingData: true })

    const infor = {
      "incidentId": Number(this.state.selectedIncidentId),
      "issue": this.state.issue,
      "status": this.state.status,
      "proposedCorrectiveAction": this.state.proposedCorrectiveAction,
      "priority": this.state.priority,
      "riskRating": this.state.riskRating,
      "personResponsible": Number(this.state.personResponsible),
      "completionDate": this.state.completionDate,
      "outcomeEvaluation": this.state.outcomeEvaluation
    }

    axios.post(`${API_URL.url}/corectiveActions/record?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {
        this.setState({ savingData: false })
        swal("successfully submitted!", "Click okay to close", "success")
          .then(() => {

          })
      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        swal(`${errorObject.message}!`, "submition failed, click okay and try again!", "error")
          .then(() => {
          })
      })
  }

  //DELETE INCIDENT FROM DATABASE
  handleDelete = (event) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, record will not be recovered!  ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          var url = `${API_URL.url}/incidents/delete?id=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.post(url).then(incident => {
            this.getIncidents();
            swal("Incident Record is deleted successfully!", "", "success");
          }).catch(error => {
            let errorObject = JSON.parse(JSON.stringify(error));
            swal(`${errorObject.message}!`, "Failed to delete, click okay and try again!", "error")
              .then(() => {
              })
          })

        }
      })
  }

  //GET ALL INCIDENTS FROM DATABASE
  getIncidents = () => {
    var url = `${API_URL.url}/incidents/all?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(incident => {
      this.setState({ incidents: incident.data, globalData: incident.data });
    }).catch(error => {
    })
  }

  getIncidentsFilteredByDate = (event) => {

    var url = `${API_URL.url}/incidents/range?start=${event.weekStart}&end=${event.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;

    axios.get(url).then(incident => {
      console.log(incident.data)
      this.setState({ incidents: incident.data, globalData: incident.data });

    }).catch(error => {

    })
  }

  //GET ALL STAFFS FROM DATABASE
  getStaffs = () => {

    var url = `${API_URL.url}/incidents/staffs?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;

    axios.get(url).then(staffs => {

      this.setState({ staffs: staffs.data });

    }).catch(error => {

    })
  }

  //GET ALL INCIDENT FROM DATABASE
  getClients = () => {

    var url = `${API_URL.url}/incidents/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(clients => {
      var arr = [{ value: -1, label: 'All incidents' }];
      clients.data.forEach(element => {
        arr.push({ value: element.id, label: element.firstname + ' ' + element.lastname });
      });
      this.setState({ clients: arr });
    }).catch(error => {

    })
  }

  //GET INPUT TEXT FROM FORM
  setTitle = (event) => {

    this.setState({ [event.name]: event.value })

  }

  handleChange = event => {
    this.setState({ searchInput: event.value }, () => {
      this.globalSearch();
    });
    var arr = [];
    this.state.globalData.forEach(element => {
      if (element.clientId == event.value) {
        arr.push(element.incidentId);
      }
    });
    console.log(arr);
    this.setState({ incidentsIdArray: arr });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.globalData.filter(value => {
      return (
        (
          (value.tblparticipant ? value.tblparticipant.id == searchInput : false)
        )
      );
    });
    this.setState({ incidents: searchInput == -1 ? this.state.globalData : filteredData });
  };



  calculateWeekRange = () => {
    let now = moment();
    var day = Number(now.weekday())
    // var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    // var end = now.add(6, 'days').format('MM-DD-YYYY')

    const start = moment().startOf('month').format('MM-DD-YYYY');
    const end = moment().endOf('month').format('MM-DD-YYYY');

    this.setState({ weekStart: start, weekEnd: end })
    this.getIncidentsFilteredByDate({ weekStart: start, weekEnd: end });

  }

  getNextWeek = () => {
    // var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    // start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    // var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    var start = moment(this.state.weekEnd).add(1, "days").format('MM-DD-YYYY');
    var end = moment(start).endOf('month').format('MM-DD-YYYY');

    this.setState({ weekStart: start, weekEnd: end })
    this.getIncidentsFilteredByDate({ weekStart: start, weekEnd: end });

  }

  getPreviousWeek = () => {
    // var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    // end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    // var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    var end = moment(this.state.weekStart).subtract(1, "days").format('MM-DD-YYYY');
    var start = moment(end).startOf('month').format('MM-DD-YYYY');


    this.setState({ weekStart: start, weekEnd: end })
    this.getIncidentsFilteredByDate({ weekStart: start, weekEnd: end });

  }




  render() {
    return (
      <>

        <Modal isOpen={this.state.showCorrectiveModal} toggle={() => this.setState({ showCorrectiveModal: false })} size="xl">
          <Form onSubmit={this.handleSubmitCorrectiveAction}>
            <div className="modal-header justify-content-center">
              <button
                className="close"
                type="button"
                onClick={() => this.setState({ showCorrectiveModal: false })}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <h4 className="title title-up">Corrective Form for {this.state.selectedClientName}</h4>
            </div>
            <ModalBody style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}>
              <Row>
                <Col className="pr-1" md="12">
                  <FormGroup>
                    <label htmlFor="nameSelect">Incident No. : {this.state.selectedIncidentId}</label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Row>

                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label htmlFor="participantSelect">Issue/Problem/Recommendation</label>
                        <Input
                          required
                          type="textarea"
                          name="issue"
                          onChange={event => this.setTitle(event.target)}
                        >
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="nameSelect">Proposed Corrective Action</label>
                        <Input
                          required
                          id="nameSelect"
                          type="textarea"
                          name="proposedCorrectiveAction"
                          onChange={event => this.setTitle(event.target)}
                        >
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <label>Priority</label><br></br>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                            <ul>
                              <li>Participant – potential injury</li>
                              <li>Staff – lost time or illness of less than 5 days</li>
                              <li>Visitors – no treatment or refused treatment</li>
                              <li>Services – minimal disruption</li>
                              <li>Financial – loss of less than 5K</li>
                              <li>Environmental – fire alarm from faulty equipment</li>
                            </ul>
                          </Popover>
                        }
                      >
                        <FormGroup check className="form-check-radio" inline>
                          <Label check>
                            <Input
                              required
                              defaultChecked={this.state.selectedIncidentIdImpactAssessment == "insignificant" ? true : false}
                              id="exampleRadios1"
                              name="priority"
                              type="radio"
                              value="insignificant"
                              onClick={(event) => {
                                const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                if (index >= 0) {
                                  const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                  this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                }


                                this.setTitle(event.target)
                              }}
                            ></Input>
                            <span className="form-check-sign"></span>
                            <Badge id="badgeInsignificant" color="success">Insignificant</Badge>

                          </Label>
                        </FormGroup>
                      </OverlayTrigger>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                            <ul>
                              <li>Participant – first aid attention </li>
                              <li>Staff – lost time or illness of 5-10 days</li>
                              <li>Visitors – first aid attention </li>
                              <li>Services – disruption to some users </li>
                              <li>Financial – loss less than $10K and greater than 5K </li>
                              <li>Environmental -  small fire from faulty equipment </li>
                            </ul>
                          </Popover>
                        }
                      >
                        <FormGroup check className="form-check-radio" inline>
                          <Label check>
                            <Input
                              defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Minor" ? true : false}
                              id="exampleRadios1"
                              name="priority"
                              type="radio"
                              value="Minor"
                              onClick={(event) => {
                                const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                if (index >= 0) {
                                  const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                  this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                }


                                this.inputValue(event.target)
                              }}
                            ></Input>
                            <span className="form-check-sign"></span>
                            <Badge id="badgeMinor" color="success">Minor</Badge>

                          </Label>
                        </FormGroup>
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                            <ul>
                              <li>Participant – medical attention by GP required</li>
                              <li>Staff – lost time for less than 11 days or restricted duties </li>
                              <li>Visitors – medical intervention by GP </li>
                              <li>Services – disruption to all users </li>
                              <li>Financial – loss of less than $10K but greater than $50K </li>
                              <li>Environmental – fire contained in a room </li>
                            </ul>
                          </Popover>
                        }
                      >
                        <FormGroup check className="form-check-radio" inline>
                          <Label check>
                            <Input
                              defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Moderate" ? true : false}
                              id="exampleRadios2"
                              name="priority"
                              type="radio"
                              value="Moderate"
                              onClick={(event) => {
                                const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                if (index >= 0) {
                                  const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                  this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                }


                                this.setTitle(event.target)
                              }}
                            ></Input>
                            <span className="form-check-sign"></span>
                            <Badge id="badgeModerate" color="warning">Moderate</Badge>

                          </Label>
                        </FormGroup>
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                            <ul>
                              <li>Participant – permanent loss of function or disfigurement; absconding Participant; sexual assault</li>
                              <li>Staff – permanent loss of function or disfigurement; sexual assault</li>
                              <li>Visitor – as for staff</li>
                              <li>Service – major loss of service </li>
                              <li>Financial – loss of less than $50K and greater than $100K </li>
                              <li>Environmental – fire  larger than one room </li>
                            </ul>
                          </Popover>
                        }
                      >
                        <FormGroup check className="form-check-radio" inline>
                          <Label check>
                            <Input
                              defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Major" ? true : false}
                              id="exampleRadios2"
                              name="priority"
                              type="radio"
                              value="Major"
                              onClick={(event) => {
                                const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                if (index >= 0) {
                                  const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                  this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                }


                                this.setTitle(event.target)
                              }}
                            ></Input>
                            <span className="form-check-sign"></span>
                            <Badge id="badgeMajor" color="warning">Major</Badge>

                          </Label>
                        </FormGroup>
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                            <ul>
                              <li>Participant – death or hospitalisation</li>
                              <li>Staff – death or hospitalisation</li>
                              <li>Visitors – death or hospitalisation</li>
                              <li>Services – complete loss of service</li>
                              <li>Financial – l loss less than $100K</li>
                              <li>Environmental – fire requiring evacuation</li>
                            </ul>
                          </Popover>
                        }
                      >
                        <FormGroup check className="form-check-radio" inline>
                          <Label check>
                            <Input
                              defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Critical" ? true : false}
                              id="exampleRadios2"
                              name="priority"
                              type="radio"
                              value="Critical"
                              onClick={(event) => {
                                const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                if (index >= 0) {
                                  const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                  this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                }


                                this.setTitle(event.target)
                              }}
                            ></Input>
                            <span className="form-check-sign"></span>
                            <Badge id="badgeCritical" color="danger">Extreme</Badge>
                          </Label>
                        </FormGroup>
                      </OverlayTrigger>
                    </Col>

                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="pr-1" md="12">
                      <label>Risk rating</label><br></br>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                            <Table className="table-bordered" responsive>
                              <thead className="text-info">
                                <tr>
                                  <th>Consequence </th>
                                  <th>Insignificant</th>
                                  <th>Minor</th>
                                  <th>Moderate</th>
                                  <th>Major</th>
                                  <th className="text-right">Extreme</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="bg-info">Rare
                                    Unlikely to reoccur – may occur in exceptional circumstances</td>
                                  <td className="bg-success">Low</td>
                                  <td className="bg-success">Low</td>
                                  <td className="bg-success">Low</td>
                                  <td className="bg-success">Low</td>
                                  <td className="text-right bg-success ">low</td>
                                </tr>
                                <tr>
                                  <td className="bg-info">Unlikely
                                    possibly could reoccur at some time in 2 – 5 years</td>
                                  <td className="bg-success">Low</td>
                                  <td className="bg-success">Low</td>
                                  <td className="bg-success">Low</td>
                                  <td className="table-warning">Medium</td>
                                  <td className="text-right table-warning">Medium</td>
                                </tr>
                                <tr>
                                  <td className="bg-info">Possible – possibly will reoccur, might occur at some time (may happen every 1 – 2 years</td>
                                  <td className="bg-success">Low</td>
                                  <td className="bg-success">Low</td>
                                  <td className="table-warning">Medium</td>
                                  <td className="table-warning">Medium</td>
                                  <td className="text-right table-warning">Medium</td>
                                </tr>
                                <tr>
                                  <td className="bg-info">Likely –
                                    will probably occur in most circumstances (several times a year)</td>
                                  <td className="bg-success">Low</td>
                                  <td className="table-warning">Medium</td>
                                  <td className="table-warning">Medium</td>
                                  <td className="bg-warning">High</td>
                                  <td className="text-right bg-warning">High</td>
                                </tr>
                                <tr>
                                  <td className="bg-info">Almost certain–
                                    is expected to occur again either immediately or within a short period of time (Likely to </td>
                                  <td className="bg-success">Low</td>
                                  <td className="table-warning">Medium</td>
                                  <td className="table-warning">Medium</td>
                                  <td className="bg-warning">High</td>
                                  <td className="text-right bg-danger ">Extreme</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Popover>
                        }
                      >
                        <FormGroup check className="form-check-radio" inline>
                          <Label check>

                            <Badge id="badgeRiskTable" color="neutral">View table</Badge>

                          </Label>
                        </FormGroup>
                      </OverlayTrigger>

                      <FormGroup check className="form-check-radio" inline>
                        <Label check>
                          <Input
                            required
                            id="exampleRadios1"
                            name="riskRating"
                            type="radio"
                            value="Low"
                            onClick={(event) => {
                              this.setTitle(event.target)
                            }}
                          ></Input>
                          <span className="form-check-sign"></span>
                          <Badge id="badgeRiskLow" color="success">Low</Badge>

                        </Label>
                      </FormGroup>
                      <FormGroup check className="form-check-radio" inline>
                        <Label check>
                          <Input
                            id="exampleRadios1"
                            name="riskRating"
                            type="radio"
                            value="Medium"
                            onClick={(event) => {
                              this.setTitle(event.target)
                            }}
                          ></Input>
                          <span className="form-check-sign"></span>
                          <Badge id="badgeRiskMedium" color="success">Medium</Badge>

                        </Label>
                      </FormGroup>
                      <FormGroup check className="form-check-radio" inline>
                        <Label check>
                          <Input
                            id="exampleRadios1"
                            name="riskRating"
                            type="radio"
                            value="High"
                            onClick={(event) => {
                              this.setTitle(event.target)
                            }}
                          ></Input>
                          <span className="form-check-sign"></span>
                          <Badge id="badgeRiskHigh" color="warning">High</Badge>

                        </Label>
                      </FormGroup>
                      <FormGroup check className="form-check-radio" inline>
                        <Label check>
                          <Input
                            id="exampleRadios1"
                            name="riskRating"
                            type="radio"
                            value="Extreme"
                            onClick={(event) => {
                              this.setTitle(event.target)
                            }}
                          ></Input>
                          <span className="form-check-sign"></span>
                          <Badge id="badgeRiskExtreme" color="danger">Extreme</Badge>

                        </Label>
                      </FormGroup>

                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Status</label>
                        <Input
                          required
                          type="select"
                          name="status"
                          onChange={event => this.setTitle(event.target)}
                        >
                          <option value="">---Select Status here---</option>
                          <option>New</option>
                          <option>Overdue</option>
                          <option>In Progress</option>
                          <option>Work Required</option>
                          <option>Completed</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Nominated completion date</label>
                        <Input
                          required
                          type="date"
                          name="completionDate"
                          onChange={event => this.setTitle(event.target)}

                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label htmlFor="participantSelect">Person Responsible for follow up</label>
                        <Input
                          required
                          id="personResponsibleSelect"
                          type="select"
                          name="personResponsible"
                          onChange={event => this.setTitle(event.target)}
                          onClick={(event) => {
                            var reportedById = event.target.value;
                            this.setState({ selectedReportedById: reportedById })
                          }}
                        >
                          <option value="">---Select Person Responsible---</option>
                          {

                            this.state.staffs.map(element => {
                              return (
                                <option
                                  value={element.id}
                                  key={element.id}>{element.firstname} {element.lastname}</option>
                              )
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="3">
                      <FormGroup>
                        <label htmlFor="nameSelect">Outcome Evaluation</label>
                        <Input
                          required
                          type="select"
                          name="outcomeEvaluation"
                          onChange={event => this.setTitle(event.target)}
                        >
                          <option value="">---Select Outcome evaluation---</option>
                          <option>risk eliminated</option>
                          <option>controlled</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>                                          </Col>
              </Row>
            </ModalBody>
            <div className="modal-footer">
              {
                this.state.savingData ?

                  <Spinner color="info" style={{ width: "3rem", height: "3rem" }} type="grow" />

                  :
                  <Button className="btn-round" color="info" type="submit" value="Submit">
                    Submit corrective action
                  </Button>
              }
              <Button
                color="danger"
                type="button"
                onClick={() => this.setState({ showCorrectiveModal: false })}
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal isOpen={this.state.showViewModal} toggle={() => this.setState({ showViewModal: false })} size="xl">
          <Form onSubmit={this.handleEditIncident}>
            <div className="modal-header justify-content-center">
              <button
                className="close"
                type="button"
                onClick={() => this.setState({ showViewModal: false })}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <h4 className="title title-up">View filled incident form {this.state.selectedIncidentId}</h4>
            </div>
            <ModalBody style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}>


              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Participant name</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedClientName}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Employee name</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedStaffName}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Site location</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentLocation}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Contacted</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentContacted}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Date</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentDate}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>witnesses</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>


                  <Table responsive>
                    <tbody>
                      <tr>
                        <th><strong>name</strong></th>
                        <th><strong>phone</strong></th>
                        <th className="text-right"><strong>email</strong></th>
                      </tr>

                      {
                        this.state.selectedIncidentWitnesses.map(element => {
                          return (
                            <tr>
                              <td> {element.name} </td>
                              <td> {element.phone}  </td>
                              <td className="text-right"> {element.email} </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>



                </li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Description of what happened before the incident</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentDescriptionBefore}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Description of what happened during the incident</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentDescriptionDuring}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Description of what happened after the incident</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentDescriptionAfter}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Incident category</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentCategory}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Impact assessment</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentIdImpactAssessment}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Reportable</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentIsReportable ? 'is reportable' : 'not reportable'}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Initial actions taken</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentInitialActionTaken}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Police reference number</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentPoliceReferenceNumber}</li>
              </ul>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}><strong>Practice leader</strong></li>
                <li class="list-group-item" style={{
                  "width": "50%",
                  "textAlign": "center"
                }}>{this.state.selectedIncidentPractiseLeader}</li>
              </ul>
            </ModalBody>
            <div className="modal-footer">
              <Button className="btn-round" href={`${API_URL.url}incidents/incidentPDF?id=${this.state.selectedIncidentId}`} color="info" type="submit" value="Submit">
                Download PDF Form
              </Button>
              <Button
                color="danger"
                type="button"
                onClick={() => this.setState({ showViewModal: false })}
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal isOpen={this.state.showIndividualModal} toggle={() => this.setState({ showIndividualModal: false })} size="xl">
          <Form >
            <div className="modal-header justify-content-center">
              <button
                className="close"
                type="button"
                onClick={() => this.setState({ showIndividualModal: false })}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <Col className="pl-1" md="4">
                <FormGroup>
                  <Input
                    required
                    value={this.value}
                    id="participantSelect"
                    type="select"
                    name="participant"
                    // onChange={event => this.setTitle(event.target)}
                    onChange={(event) => {
                      var arr = [];
                      this.state.selectedParticipantId = event.target.value;
                      this.state.incidents.forEach(element => {
                        if (element.clientId == event.target.value) {
                          arr.push(element.incidentId)
                        }
                      });
                      this.setState({ incidentsIdArray: arr })
                    }}
                    onFocus={(event) => {
                      this.state.selectedParticipantId = event.target.value;
                    }}
                  >
                    <option value="">---Select participant---</option>
                    {

                      this.state.clients.map(element => {
                        return (
                          <option
                            value={element.id}
                            key={element.id}>{element.firstname} {element.lastname}</option>
                        )
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
            </div>
            <ModalBody style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}>

              <div className="item">
                <Table responsive>
                  <thead className="text-info">
                    <tr>
                      <th>Time/date</th>
                      <th className="text-truncate" style={{ "maxWidth": "700px" }}>Incident</th>
                      <th>Participant</th>
                      <th>Incident category</th>
                      <th className="text-right">Impact</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      this.state.incidents.map(element => {
                        return (
                          <tr>
                            <td> {moment(element.date).format('DD/MM/YYYY')} {moment(element.date).format('HH:mm')} </td>
                            <td className="text-truncate" style={{ "maxWidth": "700px" }}> {element.descriptionAfter}  </td>
                            <td> {element.tblparticipant ? element.tblparticipant.firstname : ''} {element.tblparticipant ? element.tblparticipant.lastname : ''} {!element.tblparticipant ? element.otherNotParticipant : ''} </td>
                            <td> {element.category} </td>
                            <td className="text-right"> {element.impactAssessiment} </td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <Button className="btn-round" href={`${API_URL.url}incidents/incidentPDF?id=${this.state.selectedIncidentId}`} color="info" type="submit" value="Submit">
                Export all incidents for kethib chitimbe
              </Button>
              <Button
                color="danger"
                type="button"
                onClick={() => this.setState({ showIndividualModal: false })}
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal>




        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <div class="col-md-12">
                      <nav class="navbar navbar-expand-lg bg-info">
                        <div class="container">
                          <a class="navbar-brand" href="#">Monthly Filled out incident reports</a>
                          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-icons" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                          </button>
                          <div class="collapse navbar-collapse" id="example-navbar-icons">
                            <ul class="navbar-nav ml-auto">
                              <li class="nav-item active">
                                <a
                                  class={this.state.incidentsIdArray.length == 0 ? "nav-link disabled" : "nav-link"}
                                  href={`${API_URL.url}incidents/exportExcel?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&ids=${JSON.stringify(this.state.incidentsIdArray)}`}
                                // onClick={() => this.setState({ showIndividualModal: true })}

                                >
                                  {/* {this.state.incidentsIdArray.length == 0 ? 'No incidents to export' : 'Export incidents'} */}
                                  Export incidents
                                </a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" onClick={this.getPreviousWeek}><i class="now-ui-icons arrows-1_minimal-left" aria-hidden="true"></i></a>
                              </li>
                              <li class="nav-item active">
                                <a class="nav-link" href="#">
                                  <p>{moment(this.state.weekStart).format('ddd') + ', ' + moment(this.state.weekStart).format('DD') + ' ' + moment(this.state.weekStart).format('MMM')} - {moment(this.state.weekEnd).format('ddd') + ', ' + moment(this.state.weekEnd).format('DD') + ' ' + moment(this.state.weekEnd).format('MMM')}</p>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" onClick={this.getNextWeek}><i class="now-ui-icons arrows-1_minimal-right" aria-hidden="true"></i></a>
                              </li>
                              <li class="nav-item">
                                <Form>
                                  <InputGroup className="no-border">
                                    <Select
                                      name="searchInput"
                                      value={this.state.selectedClientValue}
                                      onChange={(event) => {
                                        this.setState({ selectedClientValue: event });
                                        this.handleChange(event);
                                      }}
                                      options={this.state.clients} />

                                  </InputGroup>
                                </Form>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </nav>
                    </div>

                  </Row>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-info">
                      <tr >
                        <th >Incident No.</th>
                        <th >Incident Category</th>
                        <th >participant</th>
                        <th className="text-truncate" style={{ "maxWidth": "150px" }}>Incident</th>
                        <th >Time &amp; Date</th>
                        <th >Impact</th>
                        <th className="text-truncate" style={{ "maxWidth": "150px" }} >Site location</th>
                        <th className="text-truncate" style={{ "maxWidth": "150px" }}>control</th>
                        <th className="text-right">&nbsp;</th>

                      </tr>
                    </thead>
                    <tbody>

                      {
                        this.state.incidents.map(element => {
                          return (
                            <tr>
                              <td> {element.incidentId} </td>
                              <td> {element.category} </td>
                              <td> {element.tblparticipant ? element.tblparticipant.firstname : ''} {element.tblparticipant ? element.tblparticipant.lastname : ''} {!element.tblparticipant ? element.otherNotParticipant : ''} </td>
                              <td className="text-truncate" style={{ "maxWidth": "150px" }}> {element.descriptionAfter} </td>
                              <td> {moment(element.date).format('DD/MM/YYYY')} {moment(element.date).format('HH:mm')} </td>
                              <td> {element.impactAssessiment} </td>
                              <td className="text-truncate" style={{ "maxWidth": "150px" }}> {element.location} </td>
                              <td className="text-truncate" style={{ "maxWidth": "150px" }}> {element.initialActionTaken} </td>
                              <td className="col-2">
                                <row>
                                  <Button
                                    color="info"
                                    className="btn btn-info  btn-icon-mini btn-neutral"
                                    size="sm"
                                    onClick={() => {
                                      console.log(element.otherNotParticipant == null)

                                      var date = moment(element.date).format('yyyy-MM-DD');
                                      var time = moment(element.date).format('HH:mm');

                                      this.setState({ showViewModal: true, selectedIncidentId: element.incidentId, selectedIncidentLocation: element.location, selectedStaffId: element.recordedBy.id, selectedStaffName: `${element.recordedBy.firstname} ${element.recordedBy.lastname}`, selectedClientId: element.participant ? element.tblparticipant.id : null, selectedClientName: element.otherNotParticipant == null ? `${element.tblparticipant.firstname} ${element.tblparticipant.lastname}` : element.otherNotParticipant, selectedIncidentDescriptionBefore: element.descriptionBefore, selectedIncidentDescriptionDuring: element.descriptionDuring, selectedIncidentDescriptionAfter: element.descriptionAfter, selectedIncidentInitialActionTaken: element.initialActionTaken, selectedIncidentPoliceReferenceNumber: element.policeReferenceNumber, selectedIncidentPractiseLeader: `${element.practiseLeaderRecords.firstname} ${element.practiseLeaderRecords.lastname}`, selectedIncidentIsReportable: element.isReportable, selectedIncidentContacted: element.contacted, selectedIncidentWitnesses: element.witnesses, selectedIncidentIdImpactAssessment: element.impactAssessiment, selectedIncidentCategory: element.category, selectedIncidentDate: date, selectedIncidenTime: time });
                                    }
                                    }>
                                    {/* <i className="now-ui-icons media-1_button-play" /> */}
                                    View
                                  </Button>



                                  {/* EDIT BUTTON */}

                                  {/* <Button
                                    color="info"
                                    className="btn btn-info btn-round btn-icon btn-icon-mini btn-neutral"
                                    size="sm"
                                    onClick={() => {
                                      this.state.selectedIncidentId = element.incidentId;
                                      this.state.selectedIncidentLocation = element.location;
                                      this.state.selectedStaffId = element.tbluser.id
                                      this.state.selectedStaffName = `${element.tbluser.firstname} ${element.tbluser.lastname}`
                                      this.state.selectedClientId = element.tblparticipant.id;
                                      this.state.selectedClientName = `${element.tblparticipant.firstname} ${element.tblparticipant.lastname}`;
                                      this.state.selectedIncidentDescriptionBefore = element.descriptionBefore;
                                      this.state.selectedIncidentDescriptionDuring = element.descriptionDuring;
                                      this.state.selectedIncidentDescriptionAfter = element.descriptionAfter;
                                      this.state.selectedIncidentInitialActionTaken = element.initialActionTaken;
                                      this.state.selectedIncidentPoliceReferenceNumber = element.policeReferenceNumber;
                                      this.state.selectedIncidentPractiseLeader = element.practiseLeader;
                                      this.state.selectedIncidentIsReportable = element.isReportable;
                                      this.state.selectedIncidentContacted = element.contacted;
                                      this.state.selectedIncidentWitnesses = element.witnesses;
                                      this.state.selectedIncidentIdImpactAssessment = element.impactAssessiment;
                                      this.state.selectedIncidentCategory = element.category;

                                      var date = moment(element.date).format('yyyy-MM-DD');
                                      var time = moment(element.date).format('HH:mm');

                                      this.state.selectedIncidentDate = date;
                                      this.state.selectedIncidenTime = time;
                                      this.setState({ showModal: true });
                                    }
                                    }>
                                    <i className="now-ui-icons ui-2_settings-90" />
                                  </Button> */}
                                  <Modal isOpen={this.state.showModal} toggle={() => this.setState({ showModal: false })} size="xl">
                                    <Form onSubmit={this.handleEditIncident}>
                                      <div className="modal-header justify-content-center">
                                        <button
                                          className="close"
                                          type="button"
                                          onClick={() => this.setState({ showModal: false })}
                                        >
                                          <i className="now-ui-icons ui-1_simple-remove"></i>
                                        </button>
                                        <h4 className="title title-up">Edit filled Incident</h4>
                                      </div>
                                      <ModalBody style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}>
                                        <Row>
                                          <Col className="pr-1" md="6">
                                            <FormGroup>
                                              <label htmlFor="participantSelect">Participant name</label>
                                              <Input
                                                id="participantSelect"
                                                type="select"
                                                name="clientId"
                                                onChange={event => this.inputValue(event.target)}
                                                onClick={(event) => {
                                                  var participantId = event.target.value;
                                                  this.setState({ selectedParticipantId: participantId })
                                                }}
                                              >
                                                <option className="primary" value={this.state.selectedClientId}>{this.state.selectedClientName}</option>
                                                {

                                                  this.state.clients.map(element => {
                                                    return (
                                                      <option
                                                        value={element.id}
                                                        key={element.id}>{element.firstname} {element.lastname}</option>
                                                    )
                                                  })
                                                }
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                          <Col className="pl-1" md="6">
                                            <FormGroup>
                                              <label htmlFor="nameSelect">Employee name</label>
                                              <Input
                                                id="nameSelect"
                                                type="select"
                                                name="staffId"
                                                onChange={event => this.inputValue(event.target)}
                                                onClick={(event) => {
                                                  var clientId = event.target.value;
                                                  this.setState({ selectedEmployeeId: clientId });
                                                }}>
                                                <option className="text-primary" value={this.state.selectedStaffId}>{this.state.selectedStaffName}</option>
                                                {

                                                  this.state.staffs.map(element => {
                                                    return (
                                                      <option
                                                        value={element.id}
                                                        key={element.id}>{element.firstname} {element.lastname}</option>
                                                    )
                                                  })
                                                }
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="pr-1" md="6">
                                            <FormGroup>
                                              <label>Site location</label>
                                              <Input
                                                type="text"
                                                name="siteLocation"
                                                defaultValue={this.state.selectedIncidentLocation}
                                                onChange={event => this.inputValue(event.target)}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col className="pl-1" md="2">
                                            <label>Contacted?</label><br></br>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentContacted !== false ? true : false}
                                                  id="exampleRadios1"
                                                  name="contactedRadios"
                                                  type="radio"
                                                  value="true"
                                                  onClick={event => {
                                                    this.setState({ contactedRadios: "true" })
                                                    this.inputValue(event.target)
                                                  }}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                Yes
                                              </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentContacted == "false" ? true : false}
                                                  id="exampleRadios2"
                                                  name="contactedRadios"
                                                  type="radio"
                                                  value="false"
                                                  onClick={event => {
                                                    this.setState({ contactedRadios: "false" })
                                                    this.inputValue(event.target);
                                                  }}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                No
                                              </Label>
                                            </FormGroup>
                                          </Col>

                                          {
                                            this.state.contactedRadios == "true" || this.state.selectedIncidentContacted != "false" ?
                                              <Col className="pl-1" md="4">
                                                <FormGroup>
                                                  <label htmlFor="participantSelect">If Yes select contacted personel</label>
                                                  <Input
                                                    id="contactedSelect"
                                                    type="select"
                                                    name="contacted"
                                                    onChange={event => this.inputValue(event.target)}
                                                  >
                                                    {

                                                      this.state.staffs.map(element => {
                                                        return (
                                                          <option key={element.id} value={element.id} selected={element.id == this.state.selectedIncidentContacted ? 'selected' : ''} >{element.firstname} {element.lastname}</option>
                                                        )
                                                      })
                                                    }
                                                  </Input>
                                                </FormGroup>
                                              </Col> : ""
                                          }
                                        </Row>
                                        <Row>
                                          <Col className="pr-1" md="6">
                                            <FormGroup>
                                              <label>Date</label>
                                              <Input
                                                type="date"
                                                name="date"
                                                placeholder="date"
                                                value={this.state.selectedIncidentDate}
                                                onChange={event => this.inputValue(event.target)}

                                              ></Input>
                                            </FormGroup>
                                          </Col>
                                          <Col className="pr-1" md="6">
                                            <FormGroup>
                                              <label>Time</label>
                                              <Input
                                                type="time"
                                                name="time"
                                                placeholder="time"
                                                defaultValue={this.state.selectedIncidenTime}
                                                onChange={event => this.inputValue(event.target)}

                                              ></Input>
                                            </FormGroup>
                                          </Col>

                                        </Row>

                                        <Row>
                                          <Table responsive table borderless id="tableWitness" >

                                            <tbody>
                                              <tr>
                                                <td className="text-right pl-3">

                                                  <FormGroup>
                                                    <Input
                                                      placeholder="Witness name"
                                                      type="text"
                                                      name="witnessName"
                                                      onChange={event => this.inputValue(event.target)}
                                                    />
                                                  </FormGroup>

                                                </td>
                                                <td>
                                                  <FormGroup>
                                                    <Input
                                                      placeholder="Witness number"
                                                      type="text"
                                                      name="witnessNumber"
                                                      onChange={event => this.inputValue(event.target)}
                                                    />
                                                  </FormGroup>

                                                </td>
                                                <td>

                                                  <FormGroup>
                                                    <Input
                                                      placeholder="Witness email"
                                                      type="text"
                                                      name="witnessEmail"
                                                      onChange={event => this.inputValue(event.target)}
                                                    />
                                                  </FormGroup>

                                                </td>
                                                <td className="text-right">
                                                  <FormGroup>
                                                    <Button
                                                      className="btn-round btn-block mt-0"
                                                      color="warning"
                                                      type="button"
                                                      onClick={() => {
                                                        var arr = this.state.selectedIncidentWitnesses;
                                                        arr.push({ name: this.state.witnessName, phone: this.state.witnessNumber, email: this.state.witnessEmail });
                                                        this.setState({ selectedIncidentWitnesses: arr })

                                                      }
                                                      }>
                                                      +
                                                    </Button>
                                                  </FormGroup>
                                                </td>
                                              </tr>

                                              {/* {
                                                  this.state.witnesses.map(element => {
                                                    return (
                                                      <tr>
                                                        <td className="pl-4">
                                                          {element.name}
                                                        </td>
                                                        <td>
                                                          {element.phone}
                                                        </td>
                                                        <td>
                                                          {element.email}
                                                        </td>
                                                        <td>
                                                          <FormGroup>
                                                            <Button
                                                              className="btn-round btn-block mt-0"
                                                              color="warning"
                                                              type="button"
                                                              value = {element.phone}
                                                              onClick={(event) => {
                                                                console.log('*****************')
                                                                // var arr = this.state.witnesses;
                                                                // const index = arr.findIndex(x => x.witnessName == event.target.value);
                                                                // console.log(element)
                                                                // console.log(index);
                                                                // //this.setState({ witnesses: arr })
                                                              }
                                                              }>
                                                              x
                                                            </Button>
                                                          </FormGroup>
                                                        </td>
                                                      </tr>
                                                    )
                                                  })
                                                } */}

                                              {
                                                this.state.selectedIncidentWitnesses.map(element => {
                                                  return (
                                                    <tr>
                                                      <td className="pl-4">
                                                        {element.name}
                                                      </td>
                                                      <td>
                                                        {element.phone}
                                                      </td>
                                                      <td>
                                                        {element.email}
                                                      </td>
                                                      <td>
                                                        <FormGroup>
                                                          <Button
                                                            className="btn-round btn-block mt-0"
                                                            color="warning"
                                                            type="button"
                                                            value={element.name + element.phone + element.email}
                                                            onClick={(event) => {
                                                              var arr = this.state.selectedIncidentWitnesses;
                                                              const index = arr.findIndex(x => x.name + x.phone + x.email == event.target.value);
                                                              if (index >= 0) {
                                                                arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                                              }
                                                              this.setState({ selectedIncidentWitnesses: arr });
                                                            }
                                                            }>
                                                            x
                                                          </Button>
                                                        </FormGroup>
                                                      </td>
                                                    </tr>
                                                  )
                                                })
                                              }

                                            </tbody>
                                          </Table>
                                        </Row>
                                        <Row>
                                          <Col className="pr-1" md="12">
                                            <FormGroup>
                                              <label>Description of what happened before the incident</label>
                                              <Input
                                                cols="80"
                                                rows="4"
                                                type="textarea"
                                                name="descriptionBefore"
                                                defaultValue={this.state.selectedIncidentDescriptionBefore}
                                                onChange={event => this.setTitle(event.target)}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col className="pr-1" md="12">
                                            <FormGroup>
                                              <label>Description of what happened during the incident</label>
                                              <Input
                                                cols="80"
                                                rows="4"
                                                type="textarea"
                                                name="descriptionDuring"
                                                defaultValue={this.state.selectedIncidentDescriptionDuring}
                                                onChange={event => this.setTitle(event.target)}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col className="pr-1" md="12">
                                            <FormGroup>
                                              <label>Description of what happened after the incident</label>
                                              <Input
                                                cols="80"
                                                rows="4"
                                                type="textarea"
                                                name="descriptionAfter"
                                                defaultValue={this.state.selectedIncidentDescriptionAfter}
                                                onChange={event => this.setTitle(event.target)}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="pr-1" md="12">
                                            <label>Incident category</label><br></br>
                                            {
                                              this.state.categories.map(element => {
                                                return (
                                                  <FormGroup check className="form-check-radio" inline>
                                                    <Label check>
                                                      <Input
                                                        defaultChecked={this.state.selectedIncidentCategory == element.name ? true : false}
                                                        id="exampleRadios1"
                                                        name="category"
                                                        type="radio"
                                                        value={element.name}
                                                        onClick={event => this.setTitle(event.target)}
                                                      ></Input>
                                                      <span className="form-check-sign"></span>
                                                      <Badge id={element.name.replace(' ', '')} color="neutral">{element.name}</Badge>
                                                      <UncontrolledPopover
                                                        placement={window.innerWidth < 768 ? "bottom" : "right"}
                                                        target={element.name.replace(' ', '')}

                                                      >
                                                        <PopoverHeader>{element.name} description</PopoverHeader>
                                                        <PopoverBody>
                                                          <ul>
                                                            {
                                                              element.description.map(description => {
                                                                return (
                                                                  <li>{description}</li>
                                                                )
                                                              })
                                                            }

                                                          </ul>

                                                        </PopoverBody>
                                                      </UncontrolledPopover>
                                                    </Label>
                                                  </FormGroup>
                                                )

                                              })
                                            }

                                          </Col>

                                        </Row>
                                        <hr></hr>
                                        <Row>
                                          <Col className="pr-1" md="12">
                                            <label>Impact assessment</label><br></br>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentIdImpactAssessment == "insignificant" ? true : false}
                                                  id="exampleRadios1"
                                                  name="impactAssessment"
                                                  type="radio"
                                                  value="insignificant"
                                                  onClick={(event) => {
                                                    const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                                    if (index >= 0) {
                                                      const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                                      this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                                    }


                                                    this.setTitle(event.target)
                                                  }}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                <Badge id="badgeInsignificant" color="success">Insignificant</Badge>
                                                <UncontrolledPopover
                                                  placement={window.innerWidth < 768 ? "bottom" : "right"}
                                                  target="badgeInsignificant"
                                                >
                                                  <PopoverHeader>Insignificant</PopoverHeader>
                                                  <PopoverBody>
                                                    <ul>
                                                      {
                                                        this.state.impactAssessmentDescription.map(description => {
                                                          return (
                                                            <li>{description}</li>
                                                          )
                                                        })
                                                      }

                                                    </ul>

                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Minor" ? true : false}
                                                  id="exampleRadios1"
                                                  name="impactAssessment"
                                                  type="radio"
                                                  value="Minor"
                                                  onClick={(event) => {
                                                    const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                                    if (index >= 0) {
                                                      const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                                      this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                                    }


                                                    this.inputValue(event.target)
                                                  }}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                <Badge id="badgeMinor" color="success">Minor</Badge>
                                                <UncontrolledPopover
                                                  placement={window.innerWidth < 768 ? "bottom" : "right"}
                                                  target="badgeMinor"
                                                >
                                                  <PopoverHeader>Minor</PopoverHeader>
                                                  <PopoverBody>
                                                    <ul>
                                                      {
                                                        this.state.impactAssessmentDescription.map(description => {
                                                          return (
                                                            <li>{description}</li>
                                                          )
                                                        })
                                                      }
                                                    </ul>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Moderate" ? true : false}
                                                  id="exampleRadios2"
                                                  name="impactAssessment"
                                                  type="radio"
                                                  value="Moderate"
                                                  onClick={(event) => {
                                                    const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                                    if (index >= 0) {
                                                      const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                                      this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                                    }


                                                    this.setTitle(event.target)
                                                  }}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                <Badge id="badgeModerate" color="warning">Moderate</Badge>
                                                <UncontrolledPopover
                                                  placement={window.innerWidth < 768 ? "bottom" : "right"}
                                                  target="badgeModerate"
                                                >
                                                  <PopoverHeader>Moderate</PopoverHeader>
                                                  <PopoverBody>
                                                    <ul>
                                                      {
                                                        this.state.impactAssessmentDescription.map(description => {
                                                          return (
                                                            <li>{description}</li>
                                                          )
                                                        })
                                                      }
                                                    </ul>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Major" ? true : false}
                                                  id="exampleRadios2"
                                                  name="impactAssessment"
                                                  type="radio"
                                                  value="Major"
                                                  onClick={(event) => {
                                                    const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                                    if (index >= 0) {
                                                      const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                                      this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                                    }


                                                    this.setTitle(event.target)
                                                  }}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                <Badge id="badgeMajor" color="warning">Major</Badge>
                                                <UncontrolledPopover
                                                  placement={window.innerWidth < 768 ? "bottom" : "top"}
                                                  target="badgeMajor"
                                                >
                                                  <PopoverHeader>Major</PopoverHeader>
                                                  <PopoverBody>
                                                    <ul>
                                                      {
                                                        this.state.impactAssessmentDescription.map(description => {
                                                          return (
                                                            <li>{description}</li>
                                                          )
                                                        })
                                                      }
                                                    </ul>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentIdImpactAssessment == "Critical" ? true : false}
                                                  id="exampleRadios2"
                                                  name="impactAssessment"
                                                  type="radio"
                                                  value="Critical"
                                                  onClick={(event) => {
                                                    const index = this.state.categories.findIndex(x => x.name == this.state.category);
                                                    if (index >= 0) {
                                                      const index2 = this.state.categories[index].impactRating.findIndex(x => x.name == event.target.value);
                                                      this.setState({ impactAssessmentDescription: this.state.categories[index].impactRating[index2].description })
                                                    }


                                                    this.setTitle(event.target)
                                                  }}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                <Badge id="badgeCritical" color="danger">Critical</Badge>
                                                <UncontrolledPopover
                                                  placement={window.innerWidth < 768 ? "bottom" : "top"}
                                                  target="badgeCritical"
                                                >
                                                  <PopoverHeader>Critical</PopoverHeader>
                                                  <PopoverBody>
                                                    <ul>
                                                      {
                                                        this.state.impactAssessmentDescription.map(description => {
                                                          return (
                                                            <li>{description}</li>
                                                          )
                                                        })
                                                      }
                                                    </ul>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </Label>
                                            </FormGroup>
                                          </Col>

                                        </Row>


                                        <hr></hr>
                                        <Row>
                                          <Col className="pr-1" md="6">
                                            <label>Reportable?</label><br></br>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentIsReportable ? true : false}
                                                  id="exampleRadios1"
                                                  name="isReportable"
                                                  type="radio"
                                                  value="true"
                                                  onChange={event => this.setTitle(event.target)}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                Yes
                                              </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio" inline>
                                              <Label check>
                                                <Input
                                                  defaultChecked={this.state.selectedIncidentIsReportable == false ? true : false}
                                                  id="exampleRadios2"
                                                  name="isReportable"
                                                  type="radio"
                                                  value="false"
                                                  onChange={event => this.setTitle(event.target)}
                                                ></Input>
                                                <span className="form-check-sign"></span>
                                                No
                                              </Label>
                                            </FormGroup>
                                          </Col>
                                          <Col className="pl-1" md="6">
                                            <FormGroup>
                                              <label>Initial actions taken</label>
                                              <Input
                                                type="text"
                                                name="initialActions"
                                                defaultValue={this.state.selectedIncidentInitialActionTaken}
                                                onChange={event => this.setTitle(event.target)}
                                              >
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="pr-1" md="6">
                                            <FormGroup>
                                              <label>Police reference number</label>
                                              <Input
                                                type="text"
                                                name="referenceNumber"
                                                defaultValue={this.state.selectedIncidentPoliceReferenceNumber}
                                                onChange={event => this.setTitle(event.target)}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col className="pl-1" md="6">
                                            <FormGroup>
                                              <label>Practice leader</label>
                                              <Input
                                                type="text"
                                                name="practiceLeader"
                                                defaultValue={this.state.selectedIncidentPractiseLeader}
                                                onChange={event => this.setTitle(event.target)}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <hr></hr>
                                      </ModalBody>
                                      <div className="modal-footer">
                                        <Button className="btn-round" color="info" type="submit" value="Submit">
                                          Save changes
                                        </Button>
                                        <Button
                                          color="danger"
                                          type="button"
                                          onClick={() => this.setState({ showModal: false })}
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Form>
                                  </Modal>

                                  <Button
                                    color="warning"
                                    className="btn btn-info btn-icon-mini btn-neutral"
                                    size="sm"
                                    onClick={() => {
                                      this.handleDelete(element.incidentId);
                                    }}
                                  >
                                    {/* <i className="now-ui-icons ui-1_simple-remove" /> */}
                                    Delete
                                  </Button>

                                  <Button
                                    color="info"
                                    className="btn btn-info btn-icon-mini btn-neutral"
                                    size="sm"
                                    onClick={() => {
                                      this.state.selectedIncidentId = element.incidentId;
                                      this.state.selectedClientName = element.participant ? `${element.tblparticipant.firstname} ${element.tblparticipant.lastname}` : element.otherNotParticipant;
                                      this.setState({ showCorrectiveModal: true });;
                                    }}

                                  >
                                    Corrective action
                                  </Button>


                                </row>
                              </td>

                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default IncidentRegister;
