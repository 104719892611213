/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Form,
  FormGroup,
  Input,
  Label,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalBody,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

// plugins
import axios from "axios";
import swal from '@sweetalert/with-react';
import moment from 'moment';
import ReactDOM from 'react-dom';
// import { PDFViewer } from '@react-pdf/renderer';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import GlobalFunctions from "../controllers/GlobalFunctions";
import IncidentPDF from "../controllers/IncidentPDF";
import API_URL from '../variables/API_URL';

import { thead, tbody } from "variables/general";

class ControlRegister extends React.Component {

  state = {
    incidents: []
  }

  //RUNS WHEN COMPONENT IS MOUNTED
  componentDidMount = () => {
    this.getIncidents();
  }

  //HANDLE INPUT VALUES
  inputValue = (event) => {
    this.setState({ [event.name]: event.value })
  }



  //GET ALL INCIDENTS FROM DATABASE
  getIncidents = () => {
    var url = `${API_URL.url}/corectiveActions/all?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(incident => {
      this.setState({ incidents: incident.data });
      console.log(incident.data);
    }).catch(error => {
      console.log(error);
    })
  }

  handleDelete = (event) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, record will not be recovered!  ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          var url = `${API_URL.url}/corectiveActions/delete?id=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.post(url).then(incident => {
            this.getIncidents();
            swal("Incident Record is deleted successfully!", "", "success");
          }).catch(error => {
            let errorObject = JSON.parse(JSON.stringify(error));
            swal(`${errorObject.message}!`, "Failed to delete, click okay and try again!", "error")
              .then(() => {
              })
          })

        }
      })
  }



  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Control register</CardTitle>
                </CardHeader>
                <CardBody>
                {/* <PDFViewer>
                  <IncidentPDF></IncidentPDF>
                </PDFViewer> */}
                  
                  <Table responsive>
                    <thead className="text-info">
                      <tr>
                        <th>No</th>
                        <th>Control</th>
                        <th>Control owner</th>
                        <th>Control mode</th>
                        <th>Responsible area</th>
                        <th>Control category</th>
                        <th>Review date</th>
                        <th>Control avarage effectiveness</th>
                        <th className="text-right">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        this.state.incidents.map(element => {
                          return (
                            <tr>
                              <td> {element.id} </td>
                              {/* <td> {element.control} </td>
                              <td> {element.tbluser.firstname} {element.tbluser.lastname} </td>
                              <td> {element.descriptionAfter} </td>
                              <td> {moment(element.date).format('DD/MM/YYYY')} {moment(element.date).format('HH:mm')} </td>
                              <td> {element.impactAssessiment} </td>
                              <td > {element.location} </td>
                              <td > {element.location} </td> */}
                              <td >
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() => {
                                    this.handleDelete(element.id);
                                  }}
                                >
                                  Delete
                                    </Button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ControlRegister;
